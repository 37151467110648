import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}
function lazyPage(view) {
  return () => import(`@/views/Page/${view}.vue`)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: lazyLoad('HomeView')
  },
  {
    path: '/game',
    name: 'Game',
    component: () => import(/* webpackChunkName: "game" */ '../views/Page/GameView.vue')
  },
  {
    path: '/islamyat',
    name: 'Islamyat',
    component: lazyPage('IslamyatView')
  },
  {
    path: '/kitchen',
    name: 'Kitchen',
    component: lazyPage('KitchenView')
  },
  {
    path: '/kids',
    name: 'Kids',
    component: lazyPage('KidsView')
  },
  {
    path: '/sound/:id',
    name: 'Sound',
    component: lazyPage('SoundView'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/content/:id',
    name: 'Content',
    component: lazyPage('ContentView'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/gameDetail/:id',
    name: 'GameDetail',
    component: lazyPage('GameDetailView'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/play/:id',
    name: 'Play',
    component: lazyPage('PlayView'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/search/:search_id',
    name: 'Search',
    component: lazyPage('SearchView'),
    props: true,
  },
  {
    path: '/contact',
    name: 'contact',
    component: lazyPage('ContactView'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/User/LoginView.vue'),
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import(/* webpackChunkName: "subscribe" */ '../views/User/SubscribeView.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/User/UnsubscribeView.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/User/ProfileView.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/Page/NotFoundView.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})


router.beforeEach((to, from, next) => {
  const { $cookie } = router.app.config.globalProperties
  if (to.meta.IsAuth) {
    if ($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
      next();
    } else {
      // next();
      next(window.open("https://dsplp.sd.zain.com/?p=2887461945", "_self"));
    }
  } else {
    next();
  }
})

export default router
